import { type RefObject, useEffect, useMemo, useRef, useState } from 'react'
import { type BoxProps } from '../Box'
import { type BasePopoverProps, type PopoverProps } from './Popover'

export type usePopoverArgs<T> = PopoverProps &
  Required<Pick<BasePopoverProps, 'trigger'>> & {
    triggerRef: RefObject<T>
    isDismissable?: boolean
    isDefaultOpen?: boolean
    hideArrow?: boolean
    popoverRef?: RefObject<HTMLElement>
    onClose?: () => void
  }

type PopoverArgs<T> = {
  isOpen: boolean
  setOpen: (arg: ((prev: boolean) => boolean) | boolean) => void
  triggerProps: {
    onClick: BoxProps['onClick']
    'data-trigger': string
    ref: RefObject<T>
  }
  popoverProps: PopoverProps
  onClose?: () => void
}

export function usePopover<T>({
  triggerRef,
  trigger,
  isDismissable = true,
  isDefaultOpen,
  popoverRef,
  onClose = () => {},
  ...props
}: usePopoverArgs<T>): PopoverArgs<T> {
  const [isOpen, setOpen] = useState(isDefaultOpen || false)
  const fallbackRef = useRef<Element>(null)
  const nomalizedPopoverRef = popoverRef || fallbackRef

  useEffect(() => {
    function onOutsideClick(ev: MouseEvent | TouchEvent) {
      if (
        ev.target &&
        document.body.contains(ev.target as Node) &&
        triggerRef &&
        nomalizedPopoverRef &&
        !triggerRef.current?.contains?.(ev.target as HTMLElement) &&
        !nomalizedPopoverRef.current?.contains?.(ev.target as HTMLElement)
      ) {
        // const closestTrigger = (ev.target as HTMLElement).closest(
        //   `[data-trigger="${trigger}"]`,
        // )
        // if (closestTrigger !== triggerRef.current) {
        onClose()
        setOpen(false)
        // }
      }
    }

    if (isDismissable) {
      document.addEventListener(`mousedown`, onOutsideClick)
      document.addEventListener(`touchstart`, onOutsideClick)
    }

    return () => {
      if (isDismissable) {
        document.removeEventListener(`mousedown`, onOutsideClick)
        document.removeEventListener(`touchstart`, onOutsideClick)
      }
    }
  }, [isDismissable, triggerRef, trigger, nomalizedPopoverRef, onClose])

  const triggerProps = useMemo(() => {
    return {
      onClick: () => setOpen((prev) => !prev),
      'data-trigger': trigger,
      ref: triggerRef,
    }
  }, [trigger, triggerRef])

  const popoverProps = useMemo(() => {
    return {
      isNonModal: true,
      ...props,
      trigger,
      triggerRef,
      isOpen,
      ref: nomalizedPopoverRef,
    }
  }, [isOpen, nomalizedPopoverRef, props, trigger, triggerRef])

  return {
    isOpen,
    setOpen,
    triggerProps,
    popoverProps,
  }
}
