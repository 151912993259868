import { type FocusStrategy } from '@react-types/shared'
import { useCallback, useState } from 'react'

// Mostly duplicate of "react-stately"'s useMenuTriggerState -hook. Except better and doesnt require whole lib
export interface TriggerState {
  /** Whether the overlay is currently open. */
  readonly isOpen: boolean
  /** Sets whether the overlay is open. */
  setOpen(isOpen: boolean): void
  /** Opens the overlay. */
  open(): void
  /** Closes the overlay. */
  close(): void
  /** Toggles the overlay's visibility. */
  toggle(): void
}

export interface MenuTriggerState extends TriggerState {
  /** Controls which item will be auto focused when the menu opens. */
  readonly focusStrategy: FocusStrategy

  /** Opens the menu. */
  open(focusStrategy?: FocusStrategy | null): void

  /** Toggles the menu. */
  toggle(focusStrategy?: FocusStrategy | null): void
}

export const useOverlayTriggerState = (isDefaultOpen = false): TriggerState => {
  const [isOpen, setOpen] = useState(isDefaultOpen)

  const toggle = useCallback(() => {
    setOpen((s) => !s)
  }, [])

  const open = useCallback(() => {
    setOpen(true)
  }, [])

  const close = useCallback(() => {
    setOpen(false)
  }, [])

  return {
    isOpen,
    setOpen,
    open,
    close,
    toggle,
  }
}
