import { forwardRef } from 'react'
import { Box, type BoxProps } from '../Box'
import { cardStyle } from './Card.css'

export interface CardProps extends BoxProps {}

const defaultCardPadding: BoxProps['padding'] = ['s']

export const Card = forwardRef<HTMLElement, CardProps>(function Card(
  { padding = defaultCardPadding, children, className, ...props },
  ref
) {
  return (
    <Box
      ref={ref}
      className={[cardStyle, className]}
      padding={padding}
      {...props}
    >
      {children}
    </Box>
  )
})
