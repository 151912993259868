import { type IconWeight } from '@phosphor-icons/react'
import { clsx } from 'clsx'
import { type CSSProperties } from 'react'
import { type BaseBoxProps } from '../Box'
import {
  primaryCss,
  secondaryCss,
  tertiaryCss,
  successCss,
  failureCss,
} from './icon.css'

type BaseProps = {
  width?: number | string
  height?: number | string
  as: React.ElementType | React.ForwardRefExoticComponent<any>
  variant?: 'primary' | 'secondary' | 'tertiary' | 'success' | 'failure'
  role?: string
  style?: CSSProperties
  weight?: IconWeight
}

export type IconProps = BaseProps & Omit<BaseBoxProps, keyof BaseProps>

export function Icon({
  width = 12,
  height = width,
  variant,
  as: Component,
  className,
  ...props
}: IconProps) {
  return (
    <Component
      width={width}
      height={height}
      className={clsx(className, {
        [primaryCss]: variant === 'primary',
        [secondaryCss]: variant === 'secondary',
        [tertiaryCss]: variant === 'tertiary',
        [successCss]: variant === 'success',
        [failureCss]: variant === 'failure',
      })}
      {...props}
    />
  )
}
