import { MagnifyingGlass } from '@phosphor-icons/react'
import { forwardRef } from 'react'
import { Icon } from '../Icon'
import { type InputProps, type InputV4Props, Input } from './Input'
import { inputSlotIcon } from './Input.css'
import { searchInput } from './SearchInput.css'

export type SearchInputProps =
  | (InputProps & { version?: '3' })
  | (InputV4Props & { version: '4' })

export const SearchInput = forwardRef<HTMLInputElement, SearchInputProps>(
  ({ version, ...props }, ref) => {
    if (version === '4') {
      const { slots, ...v4Props } = props as InputV4Props
      return (
        <Input
          ref={ref}
          type="search"
          version="4"
          placeholder="Search"
          slots={{
            leading: <Icon as={MagnifyingGlass} className={inputSlotIcon} />,
            ...slots,
          }}
          {...v4Props}
        />
      )
    }

    const { className, ...v3Props } = props as InputProps

    return (
      <Input
        ref={ref}
        version="3"
        type="search"
        className={[searchInput, className]}
        {...v3Props}
      />
    )
  },
)

SearchInput.displayName = 'SearchInput'
