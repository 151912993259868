import { ArrowRight, CaretDown, CaretUp, List, X } from "@phosphor-icons/react"
import { Box } from "@tokenterminal/ui/Box"
import { IconButtonV4 } from "@tokenterminal/ui/Button/IconButton.v4"
import { LinkButtonV4 } from "@tokenterminal/ui/Button/LinkButton.v4"
import { Card } from "@tokenterminal/ui/Card/Card"
import { Icon } from "@tokenterminal/ui/Icon/Icon"
import { Link } from "@tokenterminal/ui/Link"
import { Logo } from "@tokenterminal/ui/Logo"
import { Popover, usePopover } from "@tokenterminal/ui/Popover"
import { Row } from "@tokenterminal/ui/Row"
import { Stack } from "@tokenterminal/ui/Stack"
import { useEffect, useRef, useState } from "react"
import Container from "./Container"
import {
  navLinkCss,
  popoverDropdownCss,
  popoverDropdownLinkCss,
  navHeaderCss,
  desktopHeaderRowCss,
  mobileHeaderRow,
  mobileExplorerButtonCss,
} from "./Header.css"

type Props = {}

const products = [
  {
    label: "Explorer",
    href: "/products/explorer",
  },
  {
    label: "Sheets",
    href: "/products/sheets",
  },
  {
    label: "API",
    href: "/products/api",
  },
  {
    label: "Data Room",
    href: "/products/data-room",
  },
]

const listings = [
  {
    label: "Applications",
    href: "/get-listed/apps",
  },
  {
    label: "Chains",
    href: "/get-listed/chains",
  },
]

const Header = (props: Props) => {
  const productRef = useRef<HTMLButtonElement>(null)
  const listingRef = useRef<HTMLButtonElement>(null)
  const mobileRef = useRef<HTMLButtonElement>(null)
  const [isScrolled, setIsScrolled] = useState(false)
  const timeoutRef = useRef<NodeJS.Timeout | null>(null)
  const [mobileProductsExpanded, setMobileProductsExpanded] = useState(false)
  const [mobileListingssExpanded, setMobileListingsExpanded] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true)
      } else {
        setIsScrolled(false)
      }
    }

    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  const {
    triggerProps: productTriggerProps,
    popoverProps: productPopoverProps,
    setOpen: setProductsOpen,
  } = usePopover({
    placement: "bottom",
    triggerRef: productRef,
    trigger: "menu",
    isNonModal: true,
    position: "sticky",
  })

  const {
    triggerProps: mobileTriggerProps,
    popoverProps: mobilePopoverProps,
    setOpen: mobileSetOpen,
  } = usePopover({
    placement: "bottom",
    triggerRef: mobileRef,
    trigger: "menu",
    isNonModal: true,
    position: "sticky",
  })

  const handleProductSetOpen = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
    setListingsOpen(false)
    setProductsOpen(true)
  }

  const handleProductSetClose = () => {
    timeoutRef.current = setTimeout(() => {
      setProductsOpen(false)
    }, 500)
  }

  const {
    triggerProps: listingTriggerProps,
    popoverProps: listingPopoverProps,
    setOpen: setListingsOpen,
  } = usePopover({
    placement: "bottom",
    triggerRef: listingRef,
    trigger: "menu",
    isNonModal: true,
    position: "sticky",
  })

  const handleListingSetOpen = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
    setProductsOpen(false)
    setListingsOpen(true)
  }

  const handleListingSetClose = () => {
    timeoutRef.current = setTimeout(() => {
      setListingsOpen(false)
    }, 500)
  }

  return (
    <Box className={navHeaderCss} data-is-scrolled={isScrolled}>
      <Container>
        {/* Desktop */}
        <Row
          className={desktopHeaderRowCss}
          style={{
            padding: "16px 20px",
            margin: "0 -20px",
          }}
        >
          <div style={{ flex: 1 }}>
            <Link href="/">
              <Logo />
            </Link>
          </div>
          <Row gap="8x">
            {/* @ts-ignore */}
            <Link
              href="#"
              onMouseEnter={handleProductSetOpen}
              onMouseLeave={handleProductSetClose}
              {...productTriggerProps}
              className={navLinkCss}
            >
              Products
            </Link>
            <Popover
              {...productPopoverProps}
              className={popoverDropdownCss}
              hideArrow
            >
              <Stack
                padding="2x"
                gap="2x"
                onMouseEnter={handleProductSetOpen}
                onMouseLeave={handleProductSetClose}
              >
                {products.map((product) => (
                  <Link
                    key={product.label}
                    as={Card}
                    href={product.href}
                    className={popoverDropdownLinkCss}
                  >
                    <Row width="100%" justifyContent="spaceBetween">
                      {product.label}
                      <Icon as={ArrowRight} width="16" />
                    </Row>
                  </Link>
                ))}
              </Stack>
            </Popover>
            {/* @ts-ignore */}
            <Link
              href="#"
              onMouseEnter={handleListingSetOpen}
              onMouseLeave={handleListingSetClose}
              {...listingTriggerProps}
              className={navLinkCss}
            >
              Get Listed
            </Link>
            <Popover
              {...listingPopoverProps}
              className={popoverDropdownCss}
              hideArrow
            >
              <Stack
                padding="2x"
                gap="2x"
                onMouseEnter={handleListingSetOpen}
                onMouseLeave={handleListingSetClose}
              >
                {listings.map((listing) => (
                  <Link
                    key={listing.label}
                    as={Card}
                    href={listing.href}
                    className={popoverDropdownLinkCss}
                  >
                    <Row width="100%" justifyContent="spaceBetween">
                      {listing.label}
                      <Icon as={ArrowRight} width="16" />
                    </Row>
                  </Link>
                ))}
              </Stack>
            </Popover>
            <Link className={navLinkCss} href="/pricing">
              Pricing
            </Link>
            <Link
              className={navLinkCss}
              href="https://tokenterminal.com/resources"
            >
              Resources
            </Link>
            <Link className={navLinkCss} href="/about">
              About
            </Link>
          </Row>
          <Row justifyContent="flexEnd" style={{ flex: 1 }}>
            <LinkButtonV4
              color="success"
              id="explore-button"
              href="/explorer"
              style={{
                background: "hsl(165, 50.60%, 15.90%)",
                color: "hsl(165, 100%, 74%)",
              }}
            >
              Go to Explorer
            </LinkButtonV4>
          </Row>
        </Row>
        {/* Mobile */}
        <Row className={mobileHeaderRow}>
          <div style={{ flex: 1 }}>
            <Link href="/">
              <Logo />
            </Link>
          </div>
          <Row gap="1x" justifyContent="flexEnd" style={{ flex: 1 }}>
            <IconButtonV4
              {...mobileTriggerProps}
              variant="ghost"
              icon={mobilePopoverProps.isOpen ? X : List}
              className={mobileExplorerButtonCss}
            />
            <Popover
              {...mobilePopoverProps}
              className={popoverDropdownCss}
              hideArrow
              style={{
                width: "calc(100vw - 20px)",
              }}
            >
              <Stack gap="2x" padding="4x">
                <button
                  className={popoverDropdownLinkCss}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                  onClick={() =>
                    setMobileProductsExpanded(!mobileProductsExpanded)
                  }
                >
                  Products
                  <Icon as={mobileProductsExpanded ? CaretUp : CaretDown} />
                </button>
                <Stack
                  gap="2x"
                  paddingLeft="6x"
                  style={{
                    display: mobileProductsExpanded ? "block" : "none",
                  }}
                >
                  {products.map((product) => (
                    <Link
                      key={product.label}
                      as={Card}
                      href={product.href}
                      className={popoverDropdownLinkCss}
                    >
                      <Row width="100%" justifyContent="spaceBetween">
                        {product.label}
                      </Row>
                    </Link>
                  ))}
                </Stack>
                <button
                  className={popoverDropdownLinkCss}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                  onClick={() =>
                    setMobileListingsExpanded(!mobileListingssExpanded)
                  }
                >
                  Get Listed
                  <Icon as={mobileListingssExpanded ? CaretUp : CaretDown} />
                </button>
                <Stack
                  gap="2x"
                  paddingLeft="6x"
                  style={{
                    display: mobileListingssExpanded ? "block" : "none",
                  }}
                >
                  {listings.map((listing) => (
                    <Link
                      key={listing.label}
                      as={Card}
                      href={listing.href}
                      className={popoverDropdownLinkCss}
                    >
                      <Row width="100%" justifyContent="spaceBetween">
                        {listing.label}
                      </Row>
                    </Link>
                  ))}
                </Stack>
                <Link className={popoverDropdownLinkCss} href="/pricing">
                  Pricing
                </Link>
                <Link
                  className={popoverDropdownLinkCss}
                  href="https://tokenterminal.com/resources"
                >
                  Resources
                </Link>
                <Link className={popoverDropdownLinkCss} href="/about">
                  About
                </Link>
                <LinkButtonV4
                  color="success"
                  id="explore-button"
                  href="/explorer"
                  style={{
                    background: "hsl(165, 50.60%, 15.90%)",
                    color: "hsl(165, 100%, 74%)",
                  }}
                >
                  Go to Explorer
                </LinkButtonV4>
              </Stack>
            </Popover>
          </Row>
        </Row>
      </Container>
    </Box>
  )
}

export default Header
