import Footer from "../common/Footer"
import Header from "../common/Header"

import "./Layout.css"

const Layout = ({ children }: { children: React.ReactNode }) => {
  return (
    <div>
      <Header />
      {children}
      <Footer />
    </div>
  )
}

export default Layout
