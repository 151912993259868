import {
  Code as Embed,
  LinkSimple as Link,
  DownloadSimple as Download,
  ArrowsOut as Expand,
  Info,
  CaretDown as Caret,
  PlusCircle as Plus,
  FloppyDisk as Save,
  PencilSimple as Pen,
  X as Close,
  ArrowSquareOut as External,
  User,
  BookOpen as Book,
  TrashSimple as Trash,
  Gear as Cog,
  Copy,
  Check,
} from '@phosphor-icons/react'
import { type SVGProps } from 'react'

// TODO refactor so icons are not loaded when importing this component
export type ButtonIconType =
  | 'embed'
  | 'link'
  | 'download'
  | 'expand'
  | 'info'
  | 'caret'
  | 'close'
  | 'plus'
  | 'save'
  | 'pen'
  | 'external'
  | 'user'
  | 'book'
  | 'delete'
  | 'cog'
  | 'copy'
  | 'check'

interface Props extends Omit<SVGProps<SVGSVGElement>, 'type'> {
  type: ButtonIconType
  standalone?: boolean
}

const getIcon = (type: ButtonIconType): React.ElementType | null => {
  switch (type) {
    case 'embed':
      return Embed
    case 'link':
      return Link
    case 'download':
      return Download
    case 'expand':
      return Expand
    case 'info':
      return Info
    case 'caret':
      return Caret
    case 'plus':
      return Plus
    case 'save':
      return Save
    case 'pen':
      return Pen
    case 'close':
      return Close
    case 'external':
      return External
    case 'user':
      return User
    case 'book':
      return Book
    case 'delete':
      return Trash
    case 'cog':
      return Cog
    case 'copy':
      return Copy
    case 'check':
      return Check
    default:
      return null
  }
}

export const ButtonIcon = ({ type, standalone, ...props }: Props) => {
  const Icon = getIcon(type)
  if (Icon) {
    return <Icon role={standalone ? 'img' : 'presentation'} {...props} />
  }
  return null
}
