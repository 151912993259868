import { forwardRef } from 'react'
import {
  Popover as AriaPopover,
  type PopoverProps as AriaPopoverProps,
  Dialog,
  OverlayArrow,
} from 'react-aria-components'
import { type BaseBoxProps, Box, type BoxProps } from '../Box'
import { arrowCss, overlayCss, popoverCss } from './Popover.css'
export { useOverlayTriggerState } from 'react-stately'

export type BasePopoverProps = Omit<AriaPopoverProps, keyof BaseBoxProps> &
  BoxProps

export type PopoverProps = BasePopoverProps & {
  isDismissable?: boolean
  hideArrow?: boolean
}

export function BasePopover(props: BasePopoverProps) {
  return <Box as={AriaPopover} {...props} />
}

export const Popover = forwardRef<HTMLElement, PopoverProps>(function Popover(
  {
    className,
    hideArrow,
    children,
    isDismissable,
    trigger,
    triggerRef,
    label,
    ...props
  },
  ref
) {
  return (
    <Box
      as={BasePopover}
      className={[popoverCss, className]}
      // @ts-ignore
      triggerRef={triggerRef}
      isNonModal
      data-no-focus-lock
      {...props}
    >
      {!hideArrow ? (
        <OverlayArrow className={overlayCss}>
          <svg width={12} height={12} viewBox="0 0 12 12" className={arrowCss}>
            <path d="M0 0 L6 6 L12 0" stroke="currentColor" />
          </svg>
        </OverlayArrow>
      ) : null}
      <Box as={Dialog} aria-label={label} ref={ref}>
        {children}
      </Box>
    </Box>
  )
})
