import { type ElementType, forwardRef } from 'react'
import { type BoxProps } from '../Box'
import { Icon } from '../Icon'
import { type IconProps } from '../Icon/Icon'
import { type ButtonComponentProps, ButtonV4 } from './Button.v4'
import { iconButtonCss } from './IconButton.v4.css'

export type IconButtonV4Props = Omit<
  BoxProps,
  'formAction' | keyof ButtonComponentProps | 'children'
> &
  ButtonComponentProps & {
    icon: ElementType
    iconProps?: Omit<IconProps, 'as'>
  }

export const IconButtonV4 = forwardRef<HTMLButtonElement, IconButtonV4Props>(
  function IconButtonV4({ className, icon, iconProps, ...props }, ref) {
    return (
      <ButtonV4 ref={ref} className={[iconButtonCss, className]} {...props}>
        <Icon as={icon} pointerEvents="none" {...iconProps} />
      </ButtonV4>
    )
  },
)
