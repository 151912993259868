import { FetchError } from "@tokenterminal/core/api"
import { AccessibilityText } from "@tokenterminal/ui/AccessibilityText"
import { Box } from "@tokenterminal/ui/Box"
import { ButtonV4 } from "@tokenterminal/ui/Button/Button.v4"
import { Card } from "@tokenterminal/ui/Card/Card"
import { Input } from "@tokenterminal/ui/InputV3"
import { Row } from "@tokenterminal/ui/Row"
import { Stack } from "@tokenterminal/ui/Stack"
import { Text } from "@tokenterminal/ui/Text"

import {
  type KeyboardEvent,
  type MouseEvent,
  useCallback,
  useReducer,
} from "react"
import { config } from "@/constants/config"

type EmailState = {
  isLoading: boolean
  hasError: boolean
  isDirty: boolean
  error?: string
  success?: string
  email: string
}
// An interface for our actions
type EmailAction =
  | {
      type: "email_update"
      email: string
    }
  | {
      type: "loading"
    }
  | {
      type: "success"
    }
  | {
      type: "error"
      error: string
    }

function emailReducer(state: EmailState, action: EmailAction) {
  switch (action.type) {
    case "email_update": {
      return {
        ...state,
        email: action.email,
        isDirty: true,
        isLoading: false,
        hasError: false,
        success: undefined,
        error: undefined,
      }
    }
    case "loading": {
      return {
        ...state,
        isDirty: false,
        isLoading: true,
        hasError: false,
        success: undefined,
        error: undefined,
      }
    }
    case "success": {
      return {
        ...state,
        success: "Thank you for subscribing!",
        isLoading: false,
      }
    }
    case "error": {
      return {
        ...state,
        isLoading: false,
        hasError: true,
        error: action.error,
      }
    }
  }
}

export function NewsletterSignup() {
  const [{ isLoading, isDirty, hasError, error, success, email }, dispatch] =
    useReducer(emailReducer, {
      email: "",
      isLoading: false,
      hasError: false,
      isDirty: true,
      success: undefined,
      error: undefined,
    })

  const submitHandler = useCallback(
    async (e: KeyboardEvent<HTMLElement> | MouseEvent<HTMLElement>) => {
      e.preventDefault()
      dispatch({ type: "loading" })

      if (!email || !email.includes("@") || email.length < 3) {
        dispatch({ type: "error", error: "Invalid email" })
        return
      }

      try {
        await fetch(config.API_URL + "/v1/subscribe-to-newsletter", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email }),
        })

        dispatch({ type: "success" })
        setTimeout(() => {
          dispatch({ type: "email_update", email: "" })
        }, 5000)
      } catch (err) {
        if (err instanceof FetchError) {
          const json = (await err.response.json()) as { message: string }
          dispatch({ type: "error", error: json.message })

          return
        }

        dispatch({ type: "error", error: "An unknown error occurred" })
      }
    },
    [email]
  )

  return (
    <div>
      <Card
        padding="7x"
        style={{
          border: "1px solid #222",
          borderRadius: "16px",
        }}
      >
        <Row
          flexDirection={["column", "row"]}
          alignItems={["flexStart", "center"]}
        >
          <Stack alignItems="flexStart" gap="1.5x">
            <Text fontWeight="500">Subscribe to our weekly newsletter</Text>
            <Text size="14" style={{ color: "#999" }}>
              Actionable insights you can’t get elsewhere.
            </Text>
          </Stack>
          {success ? (
            <Text color="success" size="14">
              {success}
            </Text>
          ) : (
            <Row
              flexDirection={["column", "row"]}
              alignItems="flexStart"
              position="relative"
              style={{ width: "400px", maxWidth: "100%" }}
            >
              <Box as="label" width="100%">
                <AccessibilityText>Email</AccessibilityText>
                <Input
                  variant="ghost"
                  placeholder="Enter your email"
                  required
                  onChange={({ target: { value } }) => {
                    dispatch({ type: "email_update", email: value })
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      submitHandler(e)
                    }
                  }}
                  autoComplete="off"
                  value={email}
                  style={{ height: "38px", fontSize: "14px" }}
                />
              </Box>
              <ButtonV4
                onClick={submitHandler}
                disabled={!isDirty}
                loading={isLoading}
              >
                Subscribe
              </ButtonV4>
              {hasError ? (
                <Text
                  color="error"
                  size="12"
                  style={{
                    position: "absolute",
                    bottom: "-20px",
                    left: "0",
                  }}
                >
                  {error}
                </Text>
              ) : null}
            </Row>
          )}
        </Row>
      </Card>
    </div>
  )
}
