import { type RecipeVariants } from '@vanilla-extract/recipes'
import clsx from 'clsx'
import { type ForwardedRef, forwardRef } from 'react'
import { type SVG } from '../../types'
import { Box, type BoxProps } from '../Box'
import { buttonStyle, buttonIconStyle } from './Button.css'
import { ButtonIcon, type ButtonIconType } from './ButtonIcon'

interface Props {
  icon?: ButtonIconType | SVG | null
  loading?: boolean
}

export type Variants = NonNullable<RecipeVariants<typeof buttonStyle>>

export type ButtonComponentProps = Props & Variants
export type ButtonProps = Omit<
  BoxProps,
  'formAction' | keyof ButtonComponentProps
> &
  ButtonComponentProps

type IconVariants = NonNullable<RecipeVariants<typeof buttonIconStyle>>

export const resolveIconStyleVariant = ({
  variant,
  size = 'medium',
}: Variants): IconVariants['variant'] => {
  switch (variant) {
    case 'outlined':
      switch (size) {
        case 'medium':
          return 'outlined-medium'
        default:
          return variant
      }
    default:
      return variant
  }
}

interface ButtonIconFragmentProps
  extends Pick<ButtonProps, 'variant' | 'size' | 'children'> {
  icon: ButtonIconType | SVG
}

export const ButtonIconFragment = ({
  icon,
  variant,
  size,
  children,
}: ButtonIconFragmentProps) => {
  const contentNode =
    typeof children === 'string' ? (
      // This benefits simple buttons with text content. Without it, there will be regression for now due to display: grid;
      <span>{children}</span>
    ) : (
      children
    )

  if (typeof icon === 'string') {
    return (
      <>
        <ButtonIcon
          type={icon}
          className={clsx(
            buttonIconStyle({
              variant: resolveIconStyleVariant({ variant, size }),
            }),
          )}
          standalone={!children}
        />
        {contentNode}
      </>
    )
  }
  const Icon = icon
  return (
    <>
      <Icon
        className={clsx(
          buttonIconStyle({
            variant: resolveIconStyleVariant({ variant, size }),
          }),
        )}
      />
      {contentNode}
    </>
  )
}

export const Button = forwardRef(function Button(
  props: ButtonProps,
  ref: ForwardedRef<HTMLButtonElement>,
) {
  const {
    className,
    variant,
    size,
    children,
    icon,
    loading,
    disabled,
    ...rest
  } = props

  return (
    <Box
      as="button"
      type="button"
      ref={ref}
      disabled={disabled ?? loading}
      aria-busy={loading}
      width={[null, 'fit-content']}
      justifyContent="center"
      className={[className, variant, size, buttonStyle({ variant, size })]}
      {...rest}
    >
      {icon ? (
        <ButtonIconFragment icon={icon} variant={variant} size={size}>
          {children}
        </ButtonIconFragment>
      ) : (
        children
      )}
    </Box>
  )
})
