import NextLink, { type LinkProps as NextLinkProps } from 'next/link'
import {
  type ElementType,
  forwardRef,
  type ForwardRefExoticComponent,
  type ReactNode,
} from 'react'
import { Box, type BoxProps } from '../Box'
import { TextWithCapsize as Text, type TextWithCapsizeProps } from '../Text'
import { linkStyle, resetCss } from './Link.css'
import type { UrlObject } from 'url'

/*
 * Usage:
 * <Link href="foobar"><img id="for-example-image" />Or a button looking link or whatever</Link>
 * or
 * <Link href="foobar" styled>Our default link style</Link>
 */
interface BaseProps
  extends Pick<NextLinkProps, 'prefetch' | 'shallow' | 'scroll'> {
  children: ReactNode
  forceHyperlink?: boolean // disable next link
  href: UrlObject | string
  selected?: boolean
}

export type TextLinkProps = BaseProps &
  Omit<TextWithCapsizeProps, keyof BaseProps | 'as'>

export type LinkProps = BaseProps &
  Omit<BoxProps, keyof BaseProps | 'as'> & {
    as?: ElementType | ForwardRefExoticComponent<any>
  }

// TODO: make this better hopefully
export const isExtLink = (href?: LinkProps['href']) =>
  typeof href === 'string' &&
  !!href &&
  href.trim().startsWith('http') &&
  !href.includes('tokenterminal.com')

export const Link = forwardRef<HTMLAnchorElement, LinkProps>(function Link(
  {
    as: Component = Box,
    href,
    forceHyperlink,
    prefetch = false,
    shallow = false,
    selected,
    scroll,
    className = resetCss,
    ...rest
  },
  ref,
) {
  if (isExtLink(href) || forceHyperlink) {
    return (
      <Component
        ref={ref}
        as="a"
        href={href as string}
        target={forceHyperlink ? undefined : '_blank'}
        rel="noreferrer" // Allows following all now. If an issue, we need to make preferably exclude-list for unwanted ones. Idea: use re="ugc" for forum links
        className={className}
        {...rest}
      />
    )
  }

  return (
    <Component
      ref={ref}
      as={NextLink}
      href={href as unknown as string}
      prefetch={prefetch} // Undefined considered true. If passing true, Next nags about it
      shallow={shallow}
      // automatic scroll disable upon shallow routing, simply because it makes sense. Most likely shallow used for query param changes
      scroll={typeof scroll === 'undefined' && shallow ? false : scroll}
      aria-current={selected ? 'page' : undefined}
      className={className}
      {...rest}
    />
  )
})

export const LinkText = forwardRef<HTMLAnchorElement, TextLinkProps>(
  function LinkText({ className, ...props }, ref) {
    return (
      // @ts-ignore fix how to pass propstypes
      <Link
        textDecoration="underline"
        {...props}
        ref={ref}
        className={[linkStyle, className]}
        as={Text}
      />
    )
  },
)
