import {
  type ChangeEventHandler,
  cloneElement,
  type FocusEventHandler,
  forwardRef,
  isValidElement,
  type ReactNode,
  useRef,
} from 'react'
import { Box, type BoxProps } from '../Box'
import { Icon } from '../Icon'
import { type IconProps } from '../Icon/Icon'
import {
  inputV4ContainerCss,
  defaultInput,
  ghostInput,
  inputBase,
  inputStyle,
  smallInput,
  inputV4Base,
  largeInputV4Css,
  mediumInputV4Css,
  smallInputV4Css,
} from './Input.css'

const splitInputProps = (props: Record<string, any>) => {
  const inputPropKeys = [
    'autoComplete',
    'autoFocus',
    'onChange',
    'placeholder',
    'value',
  ]

  const inputProps: Record<string, any> = {}
  const containerProps: Record<string, any> = {}

  Object.keys(props).forEach((key) => {
    if (inputPropKeys.includes(key)) {
      inputProps[key] = props[key]
    } else {
      containerProps[key] = props[key]
    }
  })

  return [inputProps, containerProps]
}

export type InputSize = 'small' | 'none'
export type OnChange = ChangeEventHandler<HTMLInputElement>
export type OnFocus = FocusEventHandler<HTMLInputElement>
export type Variant = 'secondary' | 'ghost'

interface InputV3BaseProps {
  onChange?: OnChange
  onBlur?: OnFocus
  onFocus?: OnFocus
  unstyled?: boolean
  unspaced?: boolean
  size?: InputSize | number
  variant?: Variant
}

type InputV4Size = 'small' | 'medium' | 'large'

interface InputV4BaseProps {
  onChange?: OnChange
  onBlur?: OnFocus
  onFocus?: OnFocus
  size?: InputV4Size
}

export type InputV4Props = Omit<
  BoxProps,
  keyof InputV4BaseProps | 'children' | 'size'
> &
  InputV4BaseProps & {
    slots?: {
      leading?: ReactNode
      trailing?: ReactNode
    }
  }

type InputV3Props = Omit<BoxProps, keyof InputV3BaseProps | 'children'> &
  InputV3BaseProps

export type { InputV3Props as InputProps }

export const inputDefaultSize: InputSize = 'small'

type Props =
  | (InputV3Props & { version?: '3' })
  | (InputV4Props & { version: '4' })

const iconSizeLookup = {
  small: '12',
  medium: '14',
  large: '16',
} as const

const maybeSetIconWidth = (
  element: ReactNode,
  size: keyof typeof iconSizeLookup,
) => {
  if (isValidElement(element) && element.type === Icon) {
    const newProps: Partial<IconProps> = {
      width: iconSizeLookup[size],
    }
    return cloneElement(element, newProps)
  }

  return element
}

export const Input = forwardRef<HTMLInputElement, Props>(
  ({ version, ...incomingProps }, incomingRef) => {
    const temporaryRef = useRef<HTMLInputElement>(null)
    const ref = incomingRef ?? temporaryRef

    if (version === '4') {
      const [
        inputProps,
        { className, type, size = 'medium', slots = {}, ...containerProps },
      ] = splitInputProps(incomingProps as InputV4Props)

      return (
        <Box
          // @ts-ignore
          onMouseDown={() => ref?.current?.focus()}
          className={[
            inputV4ContainerCss,
            {
              [largeInputV4Css]: size === 'large',
              [mediumInputV4Css]: size === 'medium',
              [smallInputV4Css]: size === 'small',
            },
            className,
          ]}
          {...containerProps}
        >
          {maybeSetIconWidth(slots.leading, size)}
          <Box
            as="input"
            ref={ref}
            type={type}
            className={[inputV4Base]}
            size={size}
            {...inputProps}
          />
          {maybeSetIconWidth(slots.trailing, size)}
        </Box>
      )
    }

    const {
      type = 'text',
      className,
      unstyled,
      unspaced,
      size = inputDefaultSize,
      variant = 'secondary',
      ...props
    } = incomingProps as InputV3Props

    return (
      <Box
        as="input"
        type={type}
        width="100%"
        textAlign="inherit"
        ref={ref}
        size={typeof size === 'number' ? size : undefined}
        className={[
          className,
          inputBase,
          {
            [smallInput]: !unspaced && size === 'small',
            [inputStyle]: !unstyled,
            [defaultInput]: !unstyled && variant === 'secondary',
            [ghostInput]: !unstyled && variant === 'ghost',
          },
        ]}
        {...props}
      />
    )
  },
)

Input.displayName = 'Input'

interface LabelProps {
  name?: string
}

type LProps = Omit<BoxProps, keyof LabelProps> & LabelProps

export const Label = forwardRef<HTMLLabelElement, LProps>(
  ({ name, htmlFor, ...props }, ref) => {
    return <Box as="label" htmlFor={htmlFor ?? name} {...props} ref={ref} />
  },
)

Label.displayName = 'Label'
