import {
  Children,
  cloneElement,
  forwardRef,
  type ReactNode,
  useRef,
} from 'react'
import { Box, type BoxProps } from '../Box'
import { Icon } from '../Icon'
import { TextWithCapsize as Text } from '../Text'
import {
  buttonBase,
  largeButtonCss,
  regularButtonCss,
  smallButtonCss,
  xsmallButtonCss,
  neutralButtonCss,
  successButtonCss,
  warningButtonCss,
  dangerButtonCss,
  invertedButtonCss,
  solidButtonCss,
  mutedButtonCss,
  outlineButtonCss,
  ghostButtonCss,
} from './Button.v4.css'

type Color = 'neutral' | 'success' | 'warning' | 'danger' | 'inverted'
type Size = 'xsmall' | 'small' | 'medium' | 'large'
type Variant = 'solid' | 'muted' | 'outline' | 'ghost'

interface Props {
  color?: Color
  loading?: boolean
  size?: Size
  variant?: Variant
}

export type ButtonComponentProps = Props
export type ButtonV4Props = Omit<
  BoxProps,
  'formAction' | keyof ButtonComponentProps
> &
  ButtonComponentProps

export const ButtonV4 = forwardRef<HTMLButtonElement, ButtonV4Props>(
  function Button(props, ref) {
    const {
      className,
      color = 'neutral',
      variant = 'muted',
      children,
      loading,
      disabled,
      size = 'medium',
      width = [null, 'fit-content'],
      display = 'flex',
      ...rest
    } = props
    const fallbackRef = useRef<HTMLButtonElement>(null)
    const normalizedRef = ref || fallbackRef

    const sizeLookup = {
      xsmall: '12',
      small: '12',
      medium: '14',
      large: '16',
    } as const

    return (
      <Box
        as="button"
        type="button"
        ref={normalizedRef}
        disabled={disabled ?? loading}
        aria-busy={loading}
        className={[
          buttonBase,
          {
            [largeButtonCss]: size === 'large',
            [regularButtonCss]: size === 'medium',
            [smallButtonCss]: size === 'small',
            [xsmallButtonCss]: size === 'xsmall',

            [neutralButtonCss]: color === 'neutral',
            [successButtonCss]: color === 'success',
            [warningButtonCss]: color === 'warning',
            [dangerButtonCss]: color === 'danger',
            [invertedButtonCss]: color === 'inverted',

            [solidButtonCss]: variant === 'solid',
            [mutedButtonCss]: variant === 'muted',
            [outlineButtonCss]: variant === 'outline',
            [ghostButtonCss]: variant === 'ghost',
          },
          className,
        ]}
        width={width}
        display={display}
        justifyContent="center"
        {...rest}
      >
        {Children.map(children, (child, index) => {
          if (typeof child === 'string') {
            return (
              <Text
                variant={null}
                as="span"
                key={index}
                size={sizeLookup[size]}
              >
                {child}
              </Text>
            )
            // @ts-ignore
          } else if (child && (child as ReactNode).type === Icon) {
            // @ts-ignore
            return cloneElement(child as ReactNode, {
              width: sizeLookup[size],
            })
          }

          return child
        })}
      </Box>
    )
  },
)
