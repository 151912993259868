import { Box, type BoxProps } from '../Box'
import {
  smallInput,
  inputStyle,
  defaultInput,
  ghostInput,
  inputBase,
} from './Input.css'
import { selectInput } from './Select.css'
import type { Variant } from '.'
import type { ChangeEventHandler, ReactNode } from 'react'

export type OnChange = ChangeEventHandler<HTMLSelectElement>

export interface SelectProps extends Omit<BoxProps, 'as' | 'size'> {
  children: ReactNode
  name: string
  id: string
  onChange?: OnChange
  variant?: Variant
  size?: 'small'
}

export const Option = (props: Omit<BoxProps, 'as'>) => (
  <Box as="option" fontWeight="400" {...props} />
)

export const Select = ({
  children,
  className,
  variant = 'secondary',
  size = 'small',
  ...rest
}: SelectProps) => {
  return (
    <Box
      as="select"
      fontWeight="500"
      className={[
        inputBase,
        {
          [smallInput]: size === 'small',
          [inputStyle]: true,
          [defaultInput]: variant === 'secondary',
          [ghostInput]: variant === 'ghost',
        },
        selectInput,
        className,
      ]}
      {...rest}
    >
      {children}
    </Box>
  )
}

Select.displayName = 'Select'
