import clsx from 'clsx'
import { forwardRef, type ReactNode } from 'react'
import { Link, type LinkProps, isExtLink } from '../Link/Link'
import { type ButtonComponentProps, ButtonIconFragment } from './Button'
import { buttonStyle } from './Button.css'

// TODO: enforce either children or aria-label
export type LinkButtonProps = Omit<
  LinkProps,
  'ref' | 'children' | keyof ButtonComponentProps
> &
  ButtonComponentProps & { children?: ReactNode; ['aria-label']?: string }

export const LinkButton = forwardRef<HTMLAnchorElement, LinkButtonProps>(
  function LinkButton(
    { className, variant, size, icon, children, disabled, ...props },
    ref,
  ) {
    const isExternal = isExtLink(props.href)
    return (
      <Link
        className={clsx(className, buttonStyle({ variant, size }))}
        width={[null, 'fit-content']}
        ref={ref}
        aria-disabled={disabled ? true : undefined}
        {...props}
      >
        {icon || isExternal ? (
          // TODO: fix external icon style
          <ButtonIconFragment
            icon={icon || 'external'}
            variant={variant}
            size={size}
          >
            {children}
          </ButtonIconFragment>
        ) : (
          children
        )}
      </Link>
    )
  },
)
