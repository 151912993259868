import { OAUTH_GOOGLE_CLIENT_ID } from "./app.mjs"
import type { Configuration } from "modules/configuration/types"

let apiUrl = "https://api.tokenterminal.com"

if (process.env.NEXT_PUBLIC_USE_LOCAL_SERVER === `true`) {
  apiUrl = "http://localhost:3001"
}

if (process.env.NEXT_PUBLIC_VERCEL_ENV === `preview`) {
  apiUrl = "https://api.tokenterminal.dev"
}

export const config: Configuration = {
  API_URL: apiUrl,
  API_WS_URL:
    process.env.NEXT_PUBLIC_USE_LOCAL_SERVER === `true`
      ? "http://localhost:3001"
      : "https://api.tokenterminal.com",
  API_DEFAULT_TOKEN: () =>
    process.env.NEXT_PUBLIC_USE_LOCAL_SERVER === `true`
      ? "6a19e3c9-0bb4-4295-975c-909d4eb4873a"
      : process.env.NEXT_PUBLIC_API_DEFAULT_TOKEN,
  featureSwitches: {
    GTM_ENABLED: Boolean(
      process.env.VERCEL_ENV && process.env.VERCEL_ENV !== "development"
    ),
    INTERCOM_ENABLED: true,
    PUBLIC_WEBSITE_ENABLED: true,
    HISTORY_NAVIGATION_ENABLED: false,
    API_DOCS_ENABLED: true,
    ACCOUNT_PAGE_ENABLED: true,
    ACCOUNT_SETTINGS_ENABLED: true,
    ACCOUNT_PAGE_NAVIGATION_ENABLED: false,
    AUTHENTICATION_MANUAL_ENABLED: true,
    METRICS_PAGE_ENABLED: true,
    MARKET_SECTORS_ENABLED: true,
    TRENDING_CONTRACTS_ENABLED: true,
    LEADERBOARDS_ENABLED: true,
    FINANCIAL_STATEMENTS_ENABLED: true,
    CUSTOM_CHARTS_ENABLED: true,
    CUSTOM_TABLES_ENABLED: true,
    DOWNLOAD_ENABLED: true,
    KEY_METRICS_ENABLED: true,
    REVENUE_SHARE_ENABLED: true,
    COMPOSITION_BY_CONTRACT_ENABLED: true,
    COMPOSITION_BY_CHAIN_ENABLED: true,
    COMPETITIVE_LANDSCAPE_ENABLED: true,
    VESTING_SCHEDULE_ENABLED: true,
    DATA_TABLE_ENABLED: true,
    CHART_MORE_INFO_ENABLED: true,
    CHART_EMBED_ENABLED: true,
    CHART_EXPAND_ENABLED: true,
    CHART_SHARE_ENABLED: true,
    SOCKET_USE_REALTIME_PRICEFEED: false,
    CHART_SOURCES_ENABLED: true,
    PROJECT_LINKS_ENABLED: true,
    PROJECT_LISTING_FORM_ENABLED: true,
    EXTERNAL_LINKS_ENABLED: true,
    DOCS_README_ENABLED: true,
    MARTS_PRESETS_ENABLED: true,
    MARTS_CUSTOM_PRESET_ENABLED: true,
    DATASET_FUNDRAISES_ENABLED: true,
    RELATED_RESOURCES_ENABLED: true,
    COOKIE_BANNER_ENABLED: true,
    OAUTH_GOOGLE_ENABLED: Boolean(OAUTH_GOOGLE_CLIENT_ID),
    OAUTH_MICROSOFT_ENABLED: false,
    FOOTER_ENABLED: true,
    CHART_BUILDER_ALL_ENALBED: false,
  },
}
