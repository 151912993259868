import { forwardRef } from 'react'
import { Link, type LinkProps } from '../Link/Link'
import { ButtonV4, type ButtonComponentProps } from './Button.v4'

// TODO: enforce either children or aria-label
export type LinkButtonProps = Omit<
  LinkProps,
  'ref' | 'as' | keyof ButtonComponentProps
> &
  ButtonComponentProps

export const LinkButtonV4 = forwardRef<HTMLAnchorElement, LinkButtonProps>(
  function LinkButtonV4({ disabled, ...props }, ref) {
    return (
      <ButtonV4
        width={[null, 'fit-content']}
        as={Link}
        // @ts-ignore
        ref={ref}
        aria-disabled={disabled ? true : undefined}
        textDecoration="none"
        {...props}
      />
    )
  },
)
